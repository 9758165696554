import styled, { css } from 'styled-components';
import { Option } from 'react-rainbow-components';
import Picklist from 'components/Picklist';
import { color } from 'theme';
import { WHITE } from '../../constants';

export const StyledPicklist = styled(Picklist)<{ isSelectedBuiltIn: boolean }>`
    display: flex;
    width: 12.5rem;
    height: 3rem;

    input {
        height: 3rem;
        user-select: none;

        ${(props) => props.isSelectedBuiltIn && css`
            text-transform: capitalize;
        `};
    }
`;

export const StyledOption = styled(Option)<{ isBuiltIn: boolean, isSelected: boolean }>`
    ${(props) => props.isBuiltIn && css`
        span {
            text-transform: capitalize;
        }
    `};
    
    & > div {
        padding-left: 1.25rem;
    }

    ${(props) => props.isSelected && css`
        & > div {
            margin: 0 0.5rem;
            padding-left: 0.75rem;
            border-radius: 0.375rem;
            background: ${color('brand.main')};
            color: ${WHITE} !important;
        }

        svg {
            color: ${WHITE} !important;
        }
    `};
`;

export const OptionDivider = styled(Option)`
    height: 1px;
    margin: 0.75rem 0;
    background: ${color('border.divider')};
`;
