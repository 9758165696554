import { SVGProps } from 'react';

const Globe = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 480 480"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g transform="translate(60, 45) scale(0.875)">
            <path
                d="m 226.19946,16.656571 a 473.96004,333.37897 0 0 1 0,387.232509 M 59,333.21514 a 260,260 0 0 1 302,0 M 197.17143,14.79354 a 477.24462,335.68933 0 0 0 0,389.91607 M 209,15 a 195,195 0 1 0 2,0 z m 1,0 V 405 M 405,210 H 15 M 59,92.669492 a 260,260 0 0 0 302,0 M 361,330"
                fill="none"
                stroke="currentColor"
                strokeWidth="25"
                strokeMiterlimit="4"
            />
        </g>
    </svg>
);

export default Globe;
