import styled from 'styled-components';
import ButtonIcon from 'components/ButtonIcon';
import EmptyMessage from 'components/EmptyMessage';
import {
    GREY_400,
    DIVIDER,
} from '../../../constants';

export const Header = styled.div`
    display: flex;
    height: 5.375rem;
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    align-items: center;
    gap: 1rem;
    align-self: stretch;
`;

export const HeaderItems = styled.div`
    display: flex;
    align-self: stretch;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
`;

export const Title = styled.div`
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.5rem;
    color: ${GREY_400};
    flex: 1;
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    svg {
        width: 1.625rem !important;
        height: 1.625rem !important;
        font-size: 1.625rem !important;
    }
`;

export const Divider = styled.div`
    display: flex;
    align-self: stretch;
    height: 0.0625rem;
    background: ${DIVIDER};
`;

export const RightColumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 0 0;
    align-self: stretch;
`;

export const LoadingContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

export const StyledEmptyMessage = styled(EmptyMessage)`
    padding: 2rem 0;
`;

export const ListContainer = styled.div`
    display: flex;
    padding: 1.5rem 1.5rem;
    align-items: flex-start;
    gap: 0.75rem;
    align-self: stretch;
    flex-wrap: wrap;
    overflow: auto;
`;
