import { useCallback, useMemo, useState } from 'react';
import { showAppNotification } from '@rainbow-modules/app';
import {
    RenderIf,
    MenuItem,
    Tabset,
    HelpText,
} from 'react-rainbow-components';
import CustomerActivity from 'components/CustomerActivity';
import useCustomerActivity from 'data/hooks/useCustomerActivity';
import Close from 'components/icons/close';
import ListIcon from 'components/icons/list';
import Note from 'components/icons/note';
import Pencil from 'components/icons/pencil3';
import Trash from 'components/icons/trash';
import DotsVertical from 'components/icons/dotsVertical';
import UserAvatar from 'components/UserAvatar';
import Badge from 'components/Badge';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import { EntityGet } from 'data/firestore/types';
import { Stage } from 'data/firestore/agent/pipeline/stage/types';
import { Customer } from 'data/firestore/agent/customer/types';
import useSendNote from 'data/hooks/useSendNote';
import priceFormatter from 'data/services/price/formatter';
import getInitials from 'data/services/string/getInitials';
import ButtonMenu from 'components/ButtonMenu';
import ButtonIcon from 'components/ButtonIcon';
import StagePicker from 'components/OpportunityInformation/stage';
import TaskSummary from 'components/TasksInCategory/Task/Summary';
import OpportunityStuckBadge from 'components/OpportunityStuckBadge';
import { formatRelativeDate } from 'data/services/date/formatter';
import useManageOpportunities from 'data/hooks/useManageOpportunities';
import CustomerInformation from './CustomerInformation';
import getVariantFromScore from './helpers/getVariantFromScore';
import {
    DetailsContainer,
    DetailsContent,
    Header,
    Row,
    OpportunityTitle,
    OpportunityDescriptionContainer,
    StyledProgressCircular,
    OpportunityDescriptionBold,
    StyledTab,
    TabLabel,
    StyledSendNoteForm,
    TabsetContainer,
    HeaderActions,
    DetailsHeader,
    CustomerContainer,
    NotesContainer,
    Gradient,
    MainContent,
    SummaryContainer,
    OpportunityUpdateDateContainer,
    StyledClockIcon,
    StyledAlertCircle,
} from './styled';
import getScoreRange from './helpers/getScoreRange';
import { opportunityScoreLabels } from './labels';
import { WorkplaceDetailsCard } from './WorkplaceInformation';

interface Params {
    agentId: string;
    pipelineStages?: EntityGet<Stage>[];
    data?: EntityGet<Opportunity>;
    // eslint-disable-next-line react/no-unused-prop-types
    isLoadingSummary?: boolean;
    summary?: {
        summary: string;
        updatedAt: string;
    };
    onRequestClose?: () => void;
}

const OpportunityDetails = ({
    agentId = '',
    isLoadingSummary = false,
    pipelineStages = [],
    data,
    summary,
    onRequestClose = () => {},
}: Params) => {
    const [isSendingNote, setSendingNote] = useState(false);
    const [activeTab, setActiveTab] = useState('activity');

    const { id: customerId = '' } = data?.customer ?? {};

    const {
        edit: editOpportinity,
        remove: removeOpportunity,
        updateField: updateOpportunityField,
        move: moveOpportunityToPipeline,
    } = useManageOpportunities({
        agentId,
        onOpportunityDeleted: onRequestClose,
    });

    const { mutateAsync: sendNote } = useSendNote();

    const [messages, isLoadingMessages] = useCustomerActivity(
        agentId,
        (customerId || ''),
        {
            disabled: !agentId || !customerId,
            track: [agentId, customerId],
        },
    );

    const handleSendNote = useCallback(
        async (note: string, files: File[]) => {
            setSendingNote(true);
            try {
                await sendNote({
                    agentId,
                    opportunityId: data?.id,
                    customerId,
                    note,
                    files,
                });
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            setSendingNote(false);
        },
        [agentId, customerId, data?.id, sendNote],
    );

    const noteMessages = useMemo(
        () => messages.filter((message) => message.type === 'note' && message.opportunityId === data?.id),
        [messages, data?.id],
    );

    const scoreLabels = useMemo(
        () => {
            if (typeof data?.score === 'number') {
                const range = getScoreRange(data.score);
                return opportunityScoreLabels[range as string];
            }
            return undefined;
        },
        [data?.score],
    );

    const summaryUpdatedAt = useMemo(
        () => {
            if (!summary?.updatedAt) return undefined;
            return new Date(summary.updatedAt);
        },
        [summary],
    );

    const lastUpdate = useMemo(
        () => data && `Updated ${formatRelativeDate(data?.updatedAt || data?.createdAt)}`,
        [data],
    );

    const isStuck = useMemo(
        () => data?.stuck?.condition === 'stalled',
        [data],
    );

    return (
        <DetailsContainer>
            <Header>
                <ButtonIcon icon={<Close />} onClick={onRequestClose} />
                <HeaderActions>
                    <StagePicker
                        value={data?.stageId}
                        onUpdate={updateOpportunityField(data?.id as string, 'stageId')}
                        pipelineStages={pipelineStages}
                    />
                    <RenderIf isTrue={data?.amount}>
                        <Badge size="large">{priceFormatter('usd').format((data?.amount || 0) / 100)}</Badge>
                    </RenderIf>
                    <RenderIf isTrue={data?.owner}>
                        <UserAvatar
                            value={data?.owner?.uid}
                            initials={getInitials(data?.owner?.displayName || '')}
                            src={data?.owner?.photoURL}
                            assistiveText={data?.owner?.displayName}
                            title={data?.owner?.displayName}
                        />
                    </RenderIf>
                    <ButtonMenu
                        menuAlignment="right"
                        menuSize="x-small"
                        buttonVariant="base"
                        icon={<DotsVertical />}
                    >
                        <MenuItem label="Edit" onClick={() => data && editOpportinity(data)} icon={<Pencil />} />
                        <MenuItem label="Switch pipeline" onClick={() => data && moveOpportunityToPipeline(data)} icon={<Pencil />} />
                        <MenuItem label="Remove" onClick={() => data && removeOpportunity(data?.id)} icon={<Trash />} />
                    </ButtonMenu>
                </HeaderActions>
            </Header>
            <DetailsContent>
                <DetailsHeader>
                    <StyledProgressCircular
                        variant={getVariantFromScore(data?.score)}
                        value={data?.score || 0}
                    />
                    <Row>
                        <OpportunityTitle>
                            {data?.name}
                        </OpportunityTitle>
                        <OpportunityDescriptionContainer>
                            <OpportunityDescriptionBold>
                                <RenderIf isTrue={scoreLabels?.title}>
                                    {scoreLabels?.title}
                                </RenderIf>
                                <RenderIf isTrue={!scoreLabels?.title}>
                                    Assessment Pending
                                </RenderIf>
                            </OpportunityDescriptionBold>
                            <HelpText
                                text={(
                                    <p>
                                        <RenderIf isTrue={scoreLabels?.description}>
                                            {scoreLabels?.description}
                                        </RenderIf>
                                        <RenderIf isTrue={!scoreLabels?.description}>
                                            Opportunity exists, but their engagement and
                                            potential remain unclear.
                                        </RenderIf>
                                    </p>
                                )}
                            />
                        </OpportunityDescriptionContainer>
                        <OpportunityUpdateDateContainer>
                            <RenderIf isTrue={!isStuck}>
                                <StyledClockIcon />
                            </RenderIf>
                            <RenderIf isTrue={isStuck}>
                                <StyledAlertCircle />
                            </RenderIf>
                            {lastUpdate}
                            <OpportunityStuckBadge stuck={data?.stuck} />
                        </OpportunityUpdateDateContainer>
                    </Row>
                </DetailsHeader>
                <MainContent>
                    <CustomerContainer>
                        <CustomerInformation
                            agentId={agentId}
                            customerId={customerId}
                            data={data?.customer as EntityGet<Customer>}
                        />
                    </CustomerContainer>
                    <RenderIf isTrue={data}>
                        <WorkplaceDetailsCard
                            agentId={agentId}
                            customerId={customerId as string}
                            onNavigateToWorkplace={() => onRequestClose()}
                        />
                    </RenderIf>
                    <SummaryContainer>
                        <TaskSummary
                            header="Sara's got the overview for you"
                            summary={summary?.summary}
                            date={summaryUpdatedAt}
                            showDate
                            isLoading={isLoadingSummary}
                            hideDetailsLink
                        />
                    </SummaryContainer>
                </MainContent>
                <TabsetContainer>
                    <Tabset
                        variant="line"
                        activeTabName={activeTab}
                        onSelect={(e, selectedTab) => setActiveTab(selectedTab)}
                    >
                        <StyledTab
                            name="activity"
                            label={(
                                <TabLabel>
                                    <ListIcon />
                                    Activity
                                </TabLabel>
                            )}
                        />
                        <StyledTab
                            name="notes"
                            label={(
                                <TabLabel>
                                    <Note />
                                    Notes
                                    <RenderIf isTrue={noteMessages.length > 0}>
                                        <Badge size="small">{noteMessages.length}</Badge>
                                    </RenderIf>
                                </TabLabel>
                            )}
                        />
                    </Tabset>
                </TabsetContainer>
                <Row>
                    {activeTab === 'activity' && (
                        <CustomerActivity
                            agentId={agentId}
                            isLoading={isLoadingMessages}
                            messages={messages}
                            context="opportunity"
                        />
                    )}
                    {activeTab === 'notes' && (
                        <>
                            <CustomerActivity
                                agentId={agentId}
                                isLoading={isLoadingMessages}
                                messages={noteMessages}
                                context="opportunity"
                                emptyMessage="No notes have been sent"
                            />
                            <NotesContainer>
                                <Gradient />
                                <StyledSendNoteForm
                                    onSend={handleSendNote}
                                    disableSend={isSendingNote}
                                />
                            </NotesContainer>
                        </>
                    )}
                </Row>
            </DetailsContent>
        </DetailsContainer>
    );
};

export default OpportunityDetails;
