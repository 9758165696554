import { useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import Badge from 'components/Badge';
import SettingIcon from 'components/icons/setting';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import { Pipeline } from 'data/firestore/agent/pipeline/types';
import BoardIcon from 'components/icons/board';
import useUserRoles from 'hooks/useUserRoles';
import {
    ItemContainer,
    ItemTitleContainer,
    ItemTitle,
    ItemDescription,
    StyledButtonIcon,
    StyledRecordField,
    ItemStages,
} from './styled';

interface Params {
    pipeline: Pipeline;
}

const PipelineCard = ({
    pipeline,
}: Params) => {
    const { agentId = '' } = useParams();
    const { isAdmin } = useUserRoles();
    const navigate = useNavigateWithQuery();

    const description = pipeline.description ?? 'No description provided';

    const isBuiltIn = !pipeline.removable;

    const handleEditPipeline = useCallback(
        () => navigate(`/${agentId}/pipelines/${pipeline.id}/edit`),
        [agentId, pipeline, navigate],
    );

    return (
        <ItemContainer>
            <ItemTitleContainer>
                <ItemTitle isBuiltIn={isBuiltIn}>
                    {pipeline.name}
                    <RenderIf isTrue={isBuiltIn}>
                        <Badge size="small" label="Built-In" variant="brand" />
                    </RenderIf>
                </ItemTitle>
                <RenderIf isTrue={!isBuiltIn || isAdmin}>
                    <StyledButtonIcon
                        borderRadius="semi-square"
                        size="small"
                        icon={<SettingIcon />}
                        onClick={handleEditPipeline}
                    />
                </RenderIf>
            </ItemTitleContainer>
            <ItemDescription>{description}</ItemDescription>
            <ItemStages>
                <BoardIcon />
                <StyledRecordField
                    label="Stages:"
                    value={pipeline.stages.length}
                />
            </ItemStages>
        </ItemContainer>
    );
};

export default PipelineCard;
