import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { RenderIf } from 'react-rainbow-components';
import { query, where } from 'firebase/firestore';
import ChevronLeft from 'components/icons/chevronLeft';
import Button from 'components/Button';
import Plus from 'components/icons/plus';
import usePipelines from 'data/firestore/agent/pipeline/useCollection';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import useManagePipelines from './hooks/useManagePipelines';
import {
    Divider,
    Header,
    HeaderItems,
    StyledButtonIcon,
    Title,
    RightColumn,
    StyledEmptyMessage,
    ListContainer,
} from './styled';
import PipelineCard from './PipelineCard';
import LoadingShape from './loading';
import AddEditPipelineForm from './AddEditPipelineForm';

const ManagePipelinesPage = () => {
    const { agentId = '' } = useParams();
    const navigate = useNavigateWithQuery();
    const { create } = useManagePipelines({
        agentId,
        onCreated: (id: string) => navigate(`/${agentId}/pipelines/${id}/edit`),
    });

    const { data = [], isLoading } = usePipelines(agentId, {
        listQueryFn: (ref) => query(ref, where('removed', '==', false)),
        disabled: !agentId,
        track: [agentId],
    });

    const pipelines = useMemo(
        () => {
            if (data.length === 0) {
                return (
                    <StyledEmptyMessage message="No event types have been defined yet." />
                );
            }

            return data.map(
                (pipeline) => (
                    <PipelineCard
                        key={pipeline.id}
                        pipeline={pipeline}
                    />
                ),
            );
        },
        [data],
    );

    return (
        <>
            <Header>
                <HeaderItems>
                    <StyledButtonIcon
                        variant="base"
                        borderRadius="semi-square"
                        icon={<ChevronLeft />}
                        onClick={() => navigate(`/${agentId}/opportunities`)}
                    />
                    <Title title="Manage Pipelines">Manage Pipelines</Title>
                </HeaderItems>
                <RightColumn>
                    <Button
                        variant="brand"
                        size="medium"
                        borderRadius="semi-rounded"
                        onClick={() => create({})}
                    >
                        <Plus className="rainbow-m-right_small" />
                        New&nbsp;Pipeline
                    </Button>
                </RightColumn>
            </Header>
            <Divider />
            <ListContainer>
                <RenderIf isTrue={isLoading}>
                    <LoadingShape />
                </RenderIf>
                <RenderIf isTrue={!isLoading}>
                    {pipelines}
                </RenderIf>
            </ListContainer>
            <AddEditPipelineForm />
        </>
    );
};

export default ManagePipelinesPage;
