import styled from 'styled-components';
import { Card, Avatar } from 'react-rainbow-components';
import Building from '../../icons/building';

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    padding: 0 0.75rem 0.75rem 0.75rem;
    gap: 0.5rem;
`;

export const TitleContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.25rem;
    color: rgb(10, 9, 18);
    height: 2.5rem;
    margin-bottom: 0.5rem;
    padding-right: 0.8rem;
    border-radius: 1.5rem;
    &:hover {
        background-color: rgba(230, 230, 233, 0.9);
        cursor: pointer;
        text-decoration: underline;
    }
`;

export const Title = ({ name, onClick }: { name: string, onClick: () => void }) => (
    <TitleContainer onClick={onClick}>
        <Avatar
            size="medium"
            icon={<Building />}
        />
        {' '}
        {name}
    </TitleContainer>
);

export const StyledCard = styled(Card)`
    border-radius: 1.5rem;
    box-shadow: none;
    border-width: 2px;
    margin-bottom: 1rem;
    &:hover {
        box-shadow: none;
    }
    & > div:first-child {
        background-color: rgb(239, 239, 242);
        padding-left: 0.7rem;
    }
    & header {
        overflow: visible !important;
    }
`;

export const FieldContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    min-width: 0;
    width: 100%;
    & > * {
        flex-shrink: 0;
    }
`;

export const StyledLink = styled.a`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 1;
`;
