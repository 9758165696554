import { useCallback } from 'react';
import {
    confirmModal, hideAppSpinner, showAppNotification, showAppSpinner,
} from '@rainbow-modules/app';
import { useOpenModal } from '@rainbow-modules/hooks';
import ModalBody from 'components/ModalBody';
import Trash from 'components/icons/trash';
import { Calendarevent } from 'data/firestore/agent/calendarevent/types';
import useHttpMutation from 'data/firestore/useHttpMutation';
import ModalTitle from 'components/ModalTitle';
import { Pipeline } from 'data/firestore/agent/pipeline/types';
import { ADD_EDIT_PIPELINE } from '../../../../constants';

const useManagePipelines = ({
    agentId,
    onCreated = () => {},
    onDeleted = () => {},
} : {
    agentId: string;
    onCreated?: (id: string) => void;
    onDeleted?: () => void;
}) => {
    const [openPipelineForm, closePipelineForm] = useOpenModal(ADD_EDIT_PIPELINE);

    const { mutateAsync: createPipeline } = useHttpMutation({
        method: 'POST',
        pathname: `/agents/${agentId}/pipelines`,
        onSuccess: closePipelineForm,
    });

    const { mutateAsync: updatePipeline } = useHttpMutation({
        method: 'PATCH',
        onSuccess: closePipelineForm,
    });

    const { mutateAsync: deletePipeline } = useHttpMutation({
        method: 'DELETE',
    });

    const handleCreatePipeline = useCallback(
        (initialValues: Record<string, unknown>) => openPipelineForm({
            title: <ModalTitle>Create New Pipeline</ModalTitle>,
            message: 'Specify the basic information for the pipeline.',
            submitButtonLabel: 'Create',
            initialValues,
            onSubmit: async (
                {
                    name,
                    description,
                }: {
                    name: string;
                    description: string;
                },
            ) => {
                showAppSpinner();
                try {
                    const { id } = await createPipeline({
                        body: {
                            name,
                            description,
                        },
                    }) as Calendarevent;
                    onCreated(id);
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [createPipeline, onCreated, openPipelineForm],
    );

    const handleEditPipeline = useCallback(
        (pipeline: Pipeline) => openPipelineForm({
            id: pipeline.id,
            title: <ModalTitle>Edit Pipeline</ModalTitle>,
            message: 'Modify the basic information for this pipeline.',
            submitButtonLabel: 'Update',
            initialValues: pipeline,
            disabledFields: ['name'],
            onSubmit: async ({
                name: newName,
                description: newDescription,
            }: {
                name: string;
                description: string;
            }) => {
                showAppSpinner();
                try {
                    await updatePipeline({
                        pathname: `/agents/${agentId}/pipelines/${pipeline.id}`,
                        body: {
                            name: newName,
                            description: newDescription,
                        },
                    });
                } catch (error) {
                    const message = (error as any)?.message || 'Something went wrong. Please try again.';
                    showAppNotification({
                        title: 'Error',
                        description: message,
                        icon: 'error',
                        timeout: 5000,
                    });
                }
                hideAppSpinner();
            },
        }),
        [agentId, openPipelineForm, updatePipeline],
    );

    const handleDeletePipeline = useCallback(
        async (pipelineId: string) => {
            const result = await confirmModal({
                header: '',
                question: (
                    <ModalBody
                        icon={<Trash />}
                        title="Remove Pipeline?"
                        description="Once this action is confirmed, it cannot be undone."
                    />
                ),
                borderRadius: 'semi-square',
                okButtonLabel: 'Remove',
                cancelButtonLabel: 'Cancel',
            });

            if (!result) {
                return;
            }

            showAppSpinner();
            try {
                await deletePipeline({
                    pathname: `/agents/${agentId}/pipelines/${pipelineId}`,
                });
                onDeleted();
            } catch (error) {
                const message = (error as any)?.message || 'Something went wrong. Please try again.';
                showAppNotification({
                    title: 'Error',
                    description: message,
                    icon: 'error',
                    timeout: 5000,
                });
            }
            hideAppSpinner();
        },
        [agentId, deletePipeline, onDeleted],
    );

    return {
        create: handleCreatePipeline,
        edit: handleEditPipeline,
        remove: handleDeletePipeline,
    };
};

export default useManagePipelines;
