import styled, { css } from 'styled-components';
import { RecordField } from '@rainbow-modules/record';
import ButtonIcon from 'components/ButtonIcon';
import {
    GREY_300, DIVIDER,
    GREY_900,
    GREY_400,
} from '../../../../constants';

export const ItemContainer = styled.div`
    display: flex;
    width: 21.25rem;
    padding: 1.25rem;
    flex-direction: column;
    align-content: stretch;
    align-items: flex-start;
    gap: 1rem;
    border-radius: 0.375rem;
    border: 1px solid ${DIVIDER};
`;

export const ItemTitleContainer = styled.div`
    display: flex;
    align-items: flex-start;
    gap: 1rem;
    align-self: stretch;
    height: 3rem;
`;

export const ItemTitle = styled.div<{ isBuiltIn: boolean }>`
    display: inline-flex;
    flex-grow: 1;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.3125rem; 
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    gap: 0.5rem;

    ${(props) => props.isBuiltIn && css`
        text-transform: capitalize;
    `};
`;

export const ItemDescription = styled.p`
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 400;
    line-height: 1.375rem;
    color: ${GREY_300};
`;

export const StyledButtonIcon = styled(ButtonIcon)`
    color: ${GREY_300};
`;

export const ItemDurationContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.375rem;

    svg {
        width: 1.5rem !important;
        height: 1.5rem !important;
        font-size: 1.5rem !important;
    }
`;

export const StyledRecordField = styled(RecordField)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;

    & > span {
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.375rem;
        color: ${GREY_400};

        &:last-child {
            font-style: normal;
            font-weight: 400;
            line-height: 1.375rem;
            color: ${GREY_900};
        }
    }
`;

export const ItemStages = styled.div`
    display: flex;
    align-items: center;
    gap: 0rem;
    color: ${GREY_400};

    svg {
        width: 1rem !important;
        height: 1rem !important;
        font-size: 1rem !important;
    }
`;
