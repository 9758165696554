import { useCallback, useMemo } from 'react';
import { useReduxForm } from '@rainbow-modules/hooks';
import { CheckboxGroupProps } from 'react-rainbow-components/components/CheckboxGroup';
import LoadingShape from 'components/LoadingShape';
import usePipelineStages from 'data/firestore/agent/pipeline/stage/useCollection';
import usePipeline from 'data/firestore/agent/pipeline/use';
import { EntityGet } from 'data/firestore/types';
import { Stage } from 'data/firestore/agent/pipeline/stage/types';
import { Label, StyledCheckBoxGroup } from './styled';

interface Params extends Omit<CheckboxGroupProps, 'value' | 'onChange'> {
    agentId?: string;
    pipelineId?: string;
    orderInPipeline?: string[];
    value?: string[] | null;
    onChange?: (values: string[] | null) => void;
    maxSelected?: number;
}

const PipelineStageSelect = ({
    agentId = '',
    pipelineId = '',
    orderInPipeline = [],
    maxSelected,
    ...props
}: Params) => {
    const {
        value: valueInProps,
        onChange = () => {},
        ...rest
    } = useReduxForm(props);

    const { data: pipeline, isLoading: isLoadingPipeline } = usePipeline(
        agentId,
        pipelineId,
        {
            disabled: !agentId || !pipelineId || orderInPipeline.length > 0,
            track: [agentId, pipelineId],
        },
    );

    const { data: stages = [], isLoading: isLoadingStages } = usePipelineStages(
        agentId,
        pipelineId,
        {
            disabled: !agentId || !pipelineId,
            track: [agentId, pipelineId],
        },
    );

    const isLoading = useMemo(
        () => isLoadingPipeline || isLoadingStages,
        [isLoadingPipeline, isLoadingStages],
    );

    const orderedStages = useMemo(
        () => {
            const order = pipeline?.stages || orderInPipeline || [];
            if (order.length > 0) {
                return order.reduce(
                    (activeStages: EntityGet<Stage>[], stageId: string) => {
                        const found = stages.find((stage) => stage.id === stageId);
                        if (found) {
                            return [...activeStages, found];
                        }

                        return activeStages;
                    },
                    [],
                );
            }

            return stages;
        },
        [orderInPipeline, pipeline?.stages, stages],
    );

    const value = useMemo(
        () => {
            if (valueInProps) return valueInProps;
            return [];
        },
        [valueInProps],
    );

    const options = useMemo(
        () => orderedStages.map(
            (stage) => ({
                value: stage.id,
                label: <Label isBuiltIn={!stage.removable}>{stage.name}</Label>,
                disabled: value.length === maxSelected && !value.includes(stage.id),
            }),
        ),
        [maxSelected, orderedStages, value],
    );

    const handleOnChange = useCallback(
        (newValues: string[]) => {
            const maxSelectedOptions = maxSelected || orderedStages.length;
            if (newValues.length <= maxSelectedOptions) {
                onChange(newValues);
            }
        },
        [maxSelected, orderedStages.length, onChange],
    );

    if (isLoading) {
        return (
            <>
                <LoadingShape width="30%" />
                <LoadingShape width="40%" />
                <LoadingShape width="10%" />
            </>
        );
    }

    return (
        <StyledCheckBoxGroup
            {...rest}
            hideLabel
            options={options}
            value={value}
            onChange={handleOnChange}
        />
    );
};

export default PipelineStageSelect;
