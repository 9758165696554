import { useCallback, useMemo } from 'react';
import ChevronLeft from 'components/icons/chevronLeft';
import { Outlet, useParams } from 'react-router-dom';
import usePipeline from 'data/firestore/agent/pipeline/use';
import usePipelineStages from 'data/firestore/agent/pipeline/stage/useCollection';
import { EntityGet } from 'data/firestore/types';
import { Stage } from 'data/firestore/agent/pipeline/stage/types';
import useNavigateWithQuery from 'hooks/useNavigateWithQuery';
import Pencil from 'components/icons/pencil2';
import BoardIcon from 'components/icons/board';
import Trash from 'components/icons/trash';
import {
    Container, Content, Divider, Header, HeaderItems,
    LeftColumn, Options, RightColumn,
    StyledButtonIcon, Title,
} from './styled';
import MenuItem from './menuItem';
import { EditContextType } from './types';
import useManagePipelines from '../Manage/hooks/useManagePipelines';

const EditPipeline = () => {
    const { agentId = '', pipelineId = '' } = useParams();
    const navigate = useNavigateWithQuery();

    const {
        data: pipeline,
        isLoading: isLoadingPipeline,
    } = usePipeline(
        agentId,
        pipelineId,
        {
            disabled: !agentId || !pipelineId,
            track: [agentId, pipelineId],
        },
    );

    const {
        data: stages = [],
        isLoading: isLoadingPipelineStages,
    } = usePipelineStages(
        agentId,
        pipelineId,
        {
            disabled: !agentId || !pipelineId,
            track: [agentId, pipelineId],
        },
    );

    const goBack = useCallback(
        () => navigate(`/${agentId}/pipelines`),
        [agentId, navigate],
    );

    const {
        remove: removePipeline,
    } = useManagePipelines({
        agentId,
        onDeleted: goBack,
    });

    const orderedPipelineStages = useMemo(
        () => {
            const pipelineStageOrder = pipeline?.stages || [];

            return pipelineStageOrder.reduce(
                (activeStages: EntityGet<Stage>[], stageId: string) => {
                    const found = stages.find((stage) => stage.id === stageId);
                    if (found) {
                        return [...activeStages, found];
                    }

                    return activeStages;
                },
                [],
            );
        },
        [pipeline?.stages, stages],
    );

    const handleDeletePipeline = useCallback(
        () => removePipeline(pipelineId),
        [pipelineId, removePipeline],
    );

    const contextValue: EditContextType = useMemo(
        () => ({
            isLoading: isLoadingPipeline || isLoadingPipelineStages,
            pipeline,
            stages: orderedPipelineStages,
        }),
        [isLoadingPipeline, isLoadingPipelineStages, orderedPipelineStages, pipeline],
    );

    return (
        <Container>
            <Header>
                <HeaderItems>
                    <StyledButtonIcon
                        variant="base"
                        borderRadius="semi-square"
                        icon={<ChevronLeft />}
                        onClick={goBack}
                    />
                    <Title title="Edit">Edit pipeline</Title>
                    <StyledButtonIcon
                        variant="base"
                        borderRadius="semi-square"
                        disabled={!pipeline?.removable}
                        icon={<Trash />}
                        onClick={handleDeletePipeline}
                    />
                </HeaderItems>
            </Header>
            <Divider />
            <Content>
                <LeftColumn>
                    <Options>
                        <MenuItem to="general" label="Pipeline Details" icon={<Pencil />} />
                        <MenuItem to="stages" label="Stages" icon={<BoardIcon />} />
                    </Options>
                </LeftColumn>
                <RightColumn>
                    <Outlet context={contextValue} />
                </RightColumn>
            </Content>
        </Container>
    );
};

export default EditPipeline;
