/* eslint-disable react/no-unused-prop-types */
import {
    ReactNode, useCallback, useMemo,
} from 'react';
import { query, where } from 'firebase/firestore';
import { useReduxForm } from '@rainbow-modules/hooks';
import { PicklistValue } from '@rainbow-modules/listview/lib/components/AuditLogs/types';
import { Option } from 'react-rainbow-components';
import usePipelines from 'data/firestore/agent/pipeline/useCollection';
import { Pipeline } from 'data/firestore/agent/pipeline/types';
import { StyledOption, StyledPicklist } from './styled';

interface PipelinePickerProps {
    id?: string;
    className?: string;
    agentId?: string;
    name?: string;
    label?: string;
    tabIndex?: number | string;
    placeholder?: string;
    error?: ReactNode;
    required?: boolean;
    value?: string | PicklistValue;
    onChange?: (value: Pipeline) => void;
}

const PipelinePicker = (props: PipelinePickerProps) => {
    const {
        value: valueInProps,
        agentId = '',
        onChange = () => {},
        id,
        className,
        name,
        label,
        tabIndex,
        placeholder,
        error,
        required,
    } = useReduxForm(props);

    const { data: pipelines = [], isLoading } = usePipelines(agentId, {
        listQueryFn: (ref) => query(ref, where('removed', '==', false)),
        disabled: !agentId,
        track: [agentId],
    });

    const pickListvalue = useMemo(() => {
        if (!isLoading) {
            if (valueInProps) {
                const valueId = (
                    typeof valueInProps === 'string'
                        ? valueInProps
                        : (valueInProps as Pipeline).id
                );
                const found = pipelines.find((pipeline) => pipeline.id === valueId);
                if (found) {
                    if (typeof valueInProps === 'string') {
                        onChange(found);
                    }
                    return {
                        label: found.name,
                        name: found.id,
                        value: found,
                        searchableText: found.name,
                    };
                }
            }
            if (pipelines.length === 1) {
                const [pipeline] = pipelines;
                onChange(pipeline);
                const { id: pipelineId, name: pipelineName } = pipeline;
                return {
                    label: pipelineName,
                    name: pipelineId,
                    value: pipeline,
                    searchableText: pipelineName,
                };
            }
        }
        return undefined;
    }, [isLoading, onChange, pipelines, valueInProps]);

    const handleOnChange = useCallback(
        (newValue: PicklistValue) => onChange(newValue.value),
        [onChange],
    );

    const pickListOptions = useMemo(
        () => pipelines.map(
            (pipeline) => (
                <StyledOption
                    key={`pipeline__${pipeline.id}`}
                    label={pipeline.name}
                    name={pipeline.id}
                    value={pipeline.id}
                    isBuiltIn={!pipeline.removable}
                    searchableText={pipeline.name}
                />
            ),
        ),
        [pipelines],
    );

    return (
        <StyledPicklist
            id={id}
            name={name}
            className={className}
            label={label}
            labelAlignment="left"
            tabIndex={tabIndex}
            required={required}
            placeholder={placeholder}
            isLoading={isLoading}
            value={pickListvalue}
            onChange={handleOnChange}
            error={error}
        >
            <Option label="Select Pipeline" variant="header" />
            {pickListOptions}
        </StyledPicklist>
    );
};

export default PipelinePicker;
