import { useConnectModal } from '@rainbow-modules/hooks';
import FormModal from 'components/FormModal';
import { ADD_EDIT_PIPELINE } from '../../../../constants';
import Fields from './fields';

const AddEditPipelineForm = () => {
    const { ...connectedModalProps } = useConnectModal(ADD_EDIT_PIPELINE);

    return (
        <FormModal
            borderRadius="semi-square"
            size="medium"
            fields={Fields}
            {...connectedModalProps}
        />
    );
};

export default AddEditPipelineForm;
