import { SortByPickerValue } from 'components/SortByPIcker';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import { EntityGet } from 'data/firestore/types';
import checkFilters from './checkFilters';
import sortOpportunities from './sortOpportunituesFn';

const filterOpportunities = ({
    opportunities = [],
    sort = {
        sortBy: 'createdAt',
        order: 'asc',
    },
    filters = {},
    searchFilters = [],
}: {
    opportunities?: EntityGet<Opportunity>[];
    filters?: Record<string, string | string[] | null>;
    sort?: SortByPickerValue;
    searchFilters?: string[];
}) => {
    const filterdOpportunities = Object.values(filters).every((filterValue) => filterValue === null)
        ? opportunities
        : opportunities.reduce(
            (result: EntityGet<Opportunity>[], opportunity) => {
                if (checkFilters({
                    value: opportunity,
                    filterValues: filters,
                    filterNames: searchFilters,
                })) {
                    return [
                        ...result,
                        opportunity,
                    ];
                }
                return result;
            },
            [],
        );

    const { sortBy, order: sortOrder } = sort;
    return filterdOpportunities.sort(sortOpportunities(sortBy, sortOrder));
};

export default filterOpportunities;
