import { DateTime } from 'luxon';
import { Opportunity } from 'data/firestore/agent/opportunity/types';
import { EntityGet } from 'data/firestore/types';
import getDisplayName from 'data/services/profile/getDisplayName';
import tryParseNumber from 'data/services/string/parseNumber';

const checkFilters = ({
    value,
    filterValues,
    filterNames,
}: {
    value: EntityGet<Opportunity>,
    filterValues: Record<string, unknown>;
    filterNames: string[],
}) => filterNames.every(
    (filterName) => {
        const filterValue = filterValues[filterName];

        if (filterValue === null) return true;

        if (filterName === 'search') {
            const { name, customer } = value;
            const {
                phoneNumber: customerPhoneNumber = '',
                email: customerEmail = '',
            } = customer;
            const customerDisplayName = getDisplayName(customer);

            const term = (filterValue as string).toLowerCase().trim();
            const phoneNumberTerm = term.replaceAll(/\/[s++()]+\//gi, '');

            return (
                name.toLowerCase().includes(term)
                || customerEmail.toLowerCase().includes(term)
                || customerDisplayName.toLowerCase().includes(term)
                || customerPhoneNumber.includes(phoneNumberTerm)
            );
        }

        if (filterName === 'score') {
            const score = value.score as number;
            if ((filterValue as string).length === 0) return false;
            return (filterValue as string).split(',').some(
                (rangeName: string) => {
                    if (rangeName.toLowerCase() === 'high') return score >= 70;
                    if (rangeName.toLowerCase() === 'medium') return score >= 40 && score < 70;
                    if (rangeName.toLowerCase() === 'low') return score !== null && score < 40;
                    return score === null;
                },
            );
        }

        if (filterName === 'stage') {
            if ((filterValue as string).length === 0) return false;
            return (filterValue as string).split(',').some(
                (stage: string) => stage === value.stageId,
            );
        }

        if (filterName === 'amount') {
            if ((filterValue as string).length === 0) return false;
            const [min, max] = (filterValue as string).split(',').map((numericValue) => tryParseNumber(numericValue));
            const valueAmount = (value.amount || 0) / 100;
            let condition = true;
            if (min) { condition = condition && valueAmount >= min; }
            if (max) { condition = condition && valueAmount <= max; }
            return condition;
        }

        if (filterName === 'owner') {
            if ((filterValue as string).length === 0) return false;
            return (filterValue as string).split(',').some(
                (ownerId: string) => (
                    value.owner?.uid
                        ? ownerId === value.owner.uid
                        : false
                ),
            );
        }

        if (filterName === 'createdAt') {
            if ((filterValue as string).length === 0) return false;
            const [from, to = DateTime.now().toMillis()] = (filterValue as string).split(',').map((date: string) => DateTime.fromISO(date).toMillis());
            const valueTime = value.createdAt.getTime();
            return valueTime >= from && valueTime <= to;
        }

        return true;
    },
);

export default checkFilters;
