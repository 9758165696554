import { useOutletContext, useParams } from 'react-router-dom';
import { hideAppSpinner, showAppNotification, showAppSpinner } from '@rainbow-modules/app';
import useHttpMutation from 'data/firestore/useHttpMutation';
import Button from 'components/Button';
import Fields from '../../Manage/AddEditPipelineForm/fields';
import { StyledUniversalForm } from './styled';
import { EditContextType } from '../types';

const EditPipelineGeneralInfo = () => {
    const { agentId = '' } = useParams();
    const { pipeline } = useOutletContext<EditContextType>();

    const { mutateAsync: updatePipeline } = useHttpMutation({
        method: 'PATCH',
    });

    const handleSubmit = async ({ name, description }: any) => {
        showAppSpinner();
        try {
            await updatePipeline({
                pathname: `/agents/${agentId}/pipelines/${pipeline?.id}`,
                body: {
                    name,
                    description,
                },
            });
            showAppNotification({
                title: 'Success',
                description: 'Pipeline updated successfully',
                icon: 'success',
                timeout: 5000,
            });
        } catch (error) {
            const message = (error as any)?.message || 'Something went wrong. Please try again.';
            showAppNotification({
                title: 'Error',
                description: message,
                icon: 'error',
                timeout: 5000,
            });
        }
        hideAppSpinner();
    };

    const initialValues = { name: pipeline?.name, description: pipeline?.description };
    return (
        <StyledUniversalForm initialValues={initialValues} onSubmit={handleSubmit}>
            <Fields disabledFields={['name']} />
            <Button type="submit" label="Save" variant="brand" borderRadius="semi-square" disabled={!pipeline} />
        </StyledUniversalForm>
    );
};

export default EditPipelineGeneralInfo;
