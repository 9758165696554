import { Badge } from 'react-rainbow-components';
import { useNavigate } from 'react-router-dom';
import Location from '../../icons/location';
import Globe from '../../icons/globe';
import {
    Content, Title, StyledCard, StyledLink, FieldContainer,
} from './styled';
import useCustomer from '../../../data/firestore/agent/customer/use';
import useWorkplace from '../../../data/firestore/agent/workplace/use';

interface WorkplaceDetailsProps {
    agentId: string;
    customerId: string;
    onNavigateToWorkplace: () => void;
}

export const WorkplaceDetailsCard = ({
    agentId,
    customerId,
    onNavigateToWorkplace,
}: WorkplaceDetailsProps) => {
    const navigate = useNavigate();
    const { data: customer } = useCustomer(agentId, customerId);
    const { data: workplace } = useWorkplace(
        agentId,
        customer?.workplaceId || '',
        {
            disabled: !customer?.workplaceId,
        },
    );

    if (workplace) {
        const navigateToWorkplace = () => {
            onNavigateToWorkplace();
            navigate(`${agentId}/workplaces/${workplace.id}/opportunities`);
        };
        return (
            <StyledCard title={Title({ name: workplace.name, onClick: navigateToWorkplace })}>
                <Content>
                    {workplace.tags && workplace.tags.length > 0 && (
                        <div>
                            {workplace.tags.map((tag) => (
                                <Badge key={tag} variant="default" size="small" borderRadius="semi-square">
                                    {tag}
                                </Badge>
                            ))}
                        </div>
                    )}
                    {workplace.website && (
                        <FieldContainer>
                            <Globe />
                            <StyledLink href={workplace.website} target="_blank" rel="noopener noreferrer">
                                {workplace.website}
                            </StyledLink>
                        </FieldContainer>
                    )}
                    {workplace.address && (
                        <FieldContainer>
                            <Location />
                            <StyledLink href={workplace.address.addressInfo.url || `https://www.google.com/maps/search/?api=1&query=${workplace.address.formattedAddress}`} target="_blank" rel="noopener noreferrer">
                                {workplace.address.formattedAddress}
                            </StyledLink>
                        </FieldContainer>
                    )}
                </Content>
            </StyledCard>
        );
    }
    return null;
};
